import React, { createContext, Dispatch, useEffect, useReducer } from 'react';
import { ProjectListState, ReducerAction, reducerActionType } from './models';
import useAuth from '../../hooks/useAuth';
import { useProjectService } from '../../services/ProjectsService';
import {
  DEFAULT_LIMIT_SIZE,
  filterProjects,
  initialReducerState,
  makeProjectsFilterField,
  reducer,
} from './_utils';
import styled from 'styled-components';
import { notify } from '../../components/Toast/Toast';
import { Button, Col, Row } from 'react-bootstrap';
import { ExclamationTriangleFill, Unity } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { MCHammer } from '../../components/Loader';
import { ProjectCard } from './ProjectCard';
import { NewGameRequestModal } from './NewGameRequestModal';
import { AddNewProjectModal } from './AddNewProjectModal';
import { ScrollToTopToBottomBtns } from '../../components/ScrollToTopToBottomBtns/ScrollToTopToBottomBtns';
import { PERMISSION_NEW_GAME_REQUEST } from '../../services/_utils';
import { SearchInputComponent } from '../../components/SearchInput';

export const ProjectsStateContext = createContext({
  state: {} as ProjectListState,
  dispatch: {} as Dispatch<ReducerAction>,
});

export const ProjectList: React.FC = () => {
  const { hasPermission, isAdmin } = useAuth();
  const { loading, error, getAllProjects } = useProjectService();

  const [state, dispatch] = useReducer(reducer, initialReducerState());

  useEffect(() => {
    getAllProjects().then((resp) => {
      dispatch({
        type: reducerActionType.SET_PROJECTS,
        payload: makeProjectsFilterField(resp.projects),
      });
      dispatch({
        type: reducerActionType.SET_COMPANIES,
        payload: resp.companies,
      });
      dispatch({
        type: reducerActionType.SET_BLACKLISTED_SAYKIT_GAMES_COUNT,
        payload: resp.blacklistedSaykits,
      });
      dispatch({
        type: reducerActionType.SET_BLACKLISTED_UNITY_GAMES_COUNT,
        payload: resp.blacklistedUnity,
      });
    });

    document.addEventListener('scroll', onHandleScroll);
    return () => document.removeEventListener('scroll', onHandleScroll);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.needToResizeLimit && state.projects) {
      dispatch({
        type: reducerActionType.SET_NEED_TO_RESIZE_VIEW_LIMIT,
        payload: false,
      });
      dispatch({
        type: reducerActionType.SET_VIEW_PROJECTS_LIMIT,
        payload: state.viewProjectsLimit + DEFAULT_LIMIT_SIZE,
      });
    }
    // eslint-disable-next-line
  }, [state.needToResizeLimit]);

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  const onHandleScroll = () => {
    if (
      document.body.scrollHeight - 2000 <
      document.documentElement.scrollTop
    ) {
      dispatch({
        type: reducerActionType.SET_NEED_TO_RESIZE_VIEW_LIMIT,
        payload: true,
      });
    }
  };

  const onSearch = (value: string) => {
    if (value.length === 0) {
      dispatch({
        type: reducerActionType.SET_SEARCH_FILTER,
        payload: '',
      });
      dispatch({
        type: reducerActionType.SET_VIEW_PROJECTS_LIMIT,
        payload: DEFAULT_LIMIT_SIZE,
      });
      return;
    }
    dispatch({
      type: reducerActionType.SET_SEARCH_FILTER,
      payload: value.toLowerCase(),
    });
  };

  if (loading && !state.projects.length) {
    return <MCHammer />;
  }

  return (
    <div className="container">
      <ProjectsStateContext.Provider
        value={{ state: state, dispatch: dispatch }}
      >
        <StyledHeader>
          <SearchInputComponent
            setValue={onSearch}
            autoFocus={true}
            classNames="mb-3"
            datatestid="txtProjectListSearch"
          />
          <Row className="my-3">
            <Col className="d-flex align-items-center">
              <h2 className="m-0">Games</h2>

              <div>
                {state.blacklistedSayKitGamesCount > 0 && (
                  <StyledBlacklisted className="d-flex align-items-center">
                    <ExclamationTriangleFill
                      className="me-1"
                      size={18}
                      color={'#f60000'}
                    />
                    <span className="red">
                      SayKit blacklisted in
                      <Link
                        className="ms-1 text-decoration-none"
                        to={
                          '/games-info?filtered=&saykit=Black&status=testing%2Csoftlaunched%2Cpublished'
                        }
                        target={'_blank'}
                      >
                        {state.blacklistedSayKitGamesCount}{' '}
                        {state.blacklistedSayKitGamesCount > 1
                          ? 'games'
                          : 'game'}
                      </Link>
                    </span>
                  </StyledBlacklisted>
                )}
              </div>
              <div>
                {state.blacklistedUnityGamesCount > 0 && (
                  <StyledBlacklisted className="d-flex align-items-center">
                    <Unity className="me-1" size={18} color={'#131313'} />
                    <span className="red">
                      Unity blacklisted in
                      <Link
                        className="ms-1 text-decoration-none"
                        to={
                          '/games-info?filtered=&unity=Black&status=testing%2Csoftlaunched%2Cpublished'
                        }
                        target={'_blank'}
                      >
                        {state.blacklistedUnityGamesCount}{' '}
                        {state.blacklistedUnityGamesCount > 1
                          ? 'games'
                          : 'game'}
                      </Link>
                    </span>
                  </StyledBlacklisted>
                )}
              </div>
            </Col>

            <Col>
              <div className="float-end">
                {isAdmin() && (
                  <Button
                    data-testid="btnProjectListAddNewGame"
                    onClick={() =>
                      dispatch({
                        type: reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL,
                        payload: true,
                      })
                    }
                  >
                    Add New Game
                  </Button>
                )}
                {hasPermission(PERMISSION_NEW_GAME_REQUEST) && (
                  <Button
                    data-testid="btnProjectListRequestNewGame"
                    variant="success"
                    className="ms-2"
                    onClick={() => {
                      dispatch({
                        type: reducerActionType.SET_SHOW_REQUEST_MODAL,
                        payload: true,
                      });
                    }}
                  >
                    New Game Request
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </StyledHeader>
        {!!state.projects.length && (
          <StyledGrid>
            {filterProjects(state.searchFilter, state.projects)
              .slice(0, state.viewProjectsLimit)
              .map((projectInfo) => {
                return <ProjectCard key={projectInfo.id} {...projectInfo} />;
              })}
          </StyledGrid>
        )}
        {state.showRequestModal && <NewGameRequestModal />}
        {state.showAddNewProjectModal && <AddNewProjectModal />}

        <ScrollToTopToBottomBtns />
      </ProjectsStateContext.Provider>
    </div>
  );
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .card {
    animation: slideInFromBottom 0.5s ease-out;
  }
`;

const StyledHeader = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s ease-in-out;
`;

const StyledBlacklisted = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;

  .red {
    text-align: left;
    color: #dc3545;
  }
`;
