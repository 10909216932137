import { ProjectToSwitchContent } from './models';

export const makeHeaderProjectsFilterField = (
  projects: ProjectToSwitchContent[]
): ProjectToSwitchContent[] => {
  return projects.map((p) => {
    p._filter =
      p.id +
      ' ' +
      p.appKey.toLowerCase() +
      ' ' +
      p.name.toLowerCase() +
      ' ' +
      p.bundle.toLowerCase() +
      ' ' +
      p.company.toLowerCase() +
      ' ' +
      p.storeTitle.toLowerCase();

    return p;
  });
};

export const filterProjectsToSwitch = (
  projects: ProjectToSwitchContent[],
  gameSwitcherSearch: string
): ProjectToSwitchContent[] => {
  if (gameSwitcherSearch !== '') {
    const parts = gameSwitcherSearch.trim().toLowerCase().split(/\s+/);

    return projects.filter((p) => {
      for (let i = 0; i < parts.length; i++) {
        if (p._filter.indexOf(parts[i]) === -1) {
          return false;
        }
      }
      return true;
    });
  }
  return projects;
};
